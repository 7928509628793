import StackdriverErrorReporter from 'stackdriver-errors-js'

let errorHandler: {
  report: (...data: any[]) => void
  setUser: (input?: string) => void
  start?: any
}

// See client documentation in engineering wiki for setup & configuration info
const init = (
  isDevelopment?: boolean,
  stackdriver?: string,
  projectId?: string,
  service?: string
) => {
  if (isDevelopment) {
    console.log('Stackdriver Error Reporting is disabled in development')
    errorHandler = { report: console.error, setUser: () => {} }
  } else {
    errorHandler = new StackdriverErrorReporter()
    errorHandler.start?.({
      key: stackdriver,
      projectId,
      service
    })
  }
}

// 500s have server code extracted and reported by useApi wrapper
const ignored = [401, 403, 404, 500]

const report = (error: any) => {
  if (ignored.includes((error as any)?.response?.status)) return
  // wrapping with a string template forces the stack
  // to reset to actual async caller instead of anonymous
  errorHandler?.report(`${error}`)
}

const setUser = (input?: string) => {
  errorHandler?.setUser(input)
}

export { init, report, setUser }
